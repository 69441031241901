import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import { OutbackTheme } from '@maverick/themes';
import { getPublicUrl } from '@sitecore-jss/sitecore-jss-nextjs';
import { NavigationProps } from '@maverick/entity';
import { OutbackHeader } from '@maverick/ui';
import { Location } from 'components/cms/Location';
import Link from 'next/link';
import { getHomeData, HomeData } from 'src/services/HomeData.service';
import { dictionaryServiceFactory } from 'lib/dictionary-service-factory';
import { NavigationRoutes } from 'src/services/NavigationRoutes';
import { AuthenticationHandler, NativeAuthLoginPage } from '@maverick/authentication';
import { Auth0Provider } from '@auth0/auth0-react';
import { HeaderInteraction } from 'components/cms/HeaderInteraction';
import { Config } from 'src/Config';

const publicUrl = getPublicUrl();

export async function getStaticProps() {
	try {
		const homeData = await getHomeData();
		const dictionaryService = dictionaryServiceFactory.create();
		return {
			props: {
				items: await NavigationRoutes.Routes(homeData),
				homeData: homeData,
				dictionary: await dictionaryService.fetchDictionaryData('en'),
				auth0Config: Config.Auth0,
			},
		};
	} catch {
		console.warn('Error retrieving homepage data...');
		const navigationItems: NavigationProps = {
			mainItems: [],
			hamburgerItems: [],
			featuredItems: [],
			unfeaturedItems: [],
		};
		return { props: { items: navigationItems, homeData: null, dictionary: null, auth0Config: Config.Auth0 } };
	}
}

type CustomLoginProps = { items: NavigationProps; homeData: HomeData; auth0Config: typeof Config.Auth0 };

const login = ({ items, homeData, auth0Config }: CustomLoginProps): JSX.Element => {
	return (
		<ThemeProvider theme={OutbackTheme}>
			<Head>
				<title>Login</title>
				<link rel='icon' href={`${publicUrl}/favicon.ico`} />
				<link href={`${publicUrl}/css/globals.css`} rel='stylesheet' />
			</Head>
			<OutbackHeader
				linkComponent={Link}
				extra={
					<Auth0Provider
						domain={auth0Config.domain}
						clientId={auth0Config.clientId}
						redirectUri={auth0Config.redirectUriCms}
						audience={auth0Config.audience}
						useRefreshTokens
						cacheLocation='localstorage'
						brand={Config.Brand}
					>
						<AuthenticationHandler />
						<Location />
						<HeaderInteraction />
					</Auth0Provider>
				}
				items={items}
				img={<img src={homeData?.fields.Logo?.src} alt={homeData?.fields.Logo?.alt} />}
				imgMobile={<img src={homeData?.fields.LogoMobile?.src} alt={homeData?.fields.LogoMobile?.alt} />}
			/>
			<NativeAuthLoginPage />
		</ThemeProvider>
	);
};
export default login;
